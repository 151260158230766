import React, { useContext } from "react";
import { getBriefs, getClient } from "../../util/http";
import ReusableFilter from "../UI/ReusableFilter";
import { useState } from "react";
import { useEffect } from "react";
import { AuthContext } from "../../util/context";
import { getStoredItem } from "../../util/lib";

export default function BriefSubheader({ setList, setPage }) {
  const [status, setStatus] = useState("");
  const { user } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  useEffect(() => {
    async function sortData() {
      if (!status) {
        return;
      } else {
        try {
          const response = await getBriefs();
          let filteredBrief;
          if (userType?.toLowerCase() === "client") {
            const response = await getClient(user?.client_id);

            setList(
              response?.client[0]?.briefs
                ?.filter((el) => el.status?.toLowerCase() === status)
                .reverse()
            );
            return;
          }
          if (user?.job_role?.toLowerCase() === "lawyer") {
            filteredBrief = response?.briefs?.reverse().filter((el) => {
              const lawyer = JSON.parse(el.lawyer_id);
              const mappedLawyer = lawyer?.map((el) => +el);
              return (
                mappedLawyer?.includes(user?.id) &&
                el.status?.toLowerCase() === status
              );
            });
            setPage(1);
          } else if (
            user?.job_role?.toLowerCase().includes("hod") ||
            user?.job_role?.toLowerCase() === "hop"
          ) {
            filteredBrief = response?.briefs?.reverse().filter((el) => {
              return (
                el.brief_type?.toLowerCase() === user?.department?.toLowerCase()
              );
            });
          } else {
            filteredBrief = response?.briefs
              ?.reverse()
              .filter((el) => el.status?.toLowerCase() === status);

            setPage(1);
          }

          setList(filteredBrief);
          setPage(1);
        } catch (error) {
          console.log(error);
        }
      }
    }
    sortData();
  }, [status]);

  async function searchHandler(e) {
    const response = await getBriefs();
    let filteredData;
    if (userType?.toLowerCase() === "client") {
      const response = await getClient(user?.client_id);

      setList(
        response?.client[0]?.briefs
          ?.filter((el) =>
            el.brief_title
              ?.toLowerCase()
              .includes(e.target.value.trim().toLowerCase())
          )
          .reverse()
      );
      return;
    }
    if (user?.job_role?.toLowerCase() === "lawyer") {
      filteredData = response?.briefs.filter((el) => {
        const lawyer = JSON.parse(el.lawyer_id);
        const mappedLawyer = lawyer?.map((el) => +el);
        return (
          mappedLawyer?.includes(user?.id) &&
          el.brief_title
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase())
        );
      });
    } else if (
      user?.job_role?.toLowerCase().includes("hod") ||
      user?.job_role?.toLowerCase() === "hop"
    ) {
      filteredData = response?.briefs?.reverse().filter((el) => {
        return (
          el.brief_type?.toLowerCase() === user?.department?.toLowerCase() &&
          el.brief_title
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase())
        );
      });
    } else {
      filteredData = response?.briefs.filter((el) =>
        el.brief_title
          ?.toLowerCase()
          .includes(e.target.value.trim().toLowerCase())
      );
    }

    setList(filteredData);
  }

  function pendingFilter() {
    setStatus("pending");
  }

  function allFilter() {
    setStatus("");
  }

  function completedFilter() {
    setStatus("completed");
  }

  function draftFilter() {
    setStatus("draft");
  }

  function activeFilter() {
    setStatus("active");
  }

  function archivedFilter() {
    setStatus("archived");
  }

  console.log(userType);

  return (
    <ReusableFilter
      onSearch={searchHandler}
      menu={[
        { text: "All", onClick: allFilter },
        { text: "Active", onClick: activeFilter },
        {
          text: `${userType?.toLowerCase() === "client" ? "" : "Draft"}`,
          onClick: draftFilter,
        },
        { text: "Pending", onClick: pendingFilter },
        { text: "Completed", onClick: completedFilter },
        // { text: "Archived", onClick: archivedFilter },
      ]}
    />
  );
}
