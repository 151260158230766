import {
  Flex,
  FormControl,
  InputGroup,
  Input,
  InputRightAddon,
  InputLeftAddon,
} from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import { Fragment, useEffect, useState } from "react";
import InvoiceStat from "../../brief/create brief/InvoiceStat";
import Receipt from "./Receipt";
import { getStoredItem } from "../../../util/lib";
import { getVAT } from "../../../util/http";

export default function ClientDetail({ invoice }) {
  const invoicesArr = invoice?.invoice && JSON.parse(invoice?.invoice);
  const userType = getStoredItem("user-type");

  const [vatVal, setVatVal] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getVAT();

        setVatVal(+response?.vat);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const subTotal = invoicesArr
    ?.map((el) => el.amount)
    .reduce((acc, sum) => acc + sum, 0);

  const total = invoicesArr
    ?.map((el) => {
      return { amount: el?.vat ? el.amount * vatVal + el.amount : el.amount };
    })
    .map((el) => el.amount)
    .reduce((acc, sum) => acc + sum, 0);

  const vat = total - subTotal;

  const amountArr = invoicesArr?.map((el) => +el.amount);
  const totalAmount = amountArr?.reduce((acc, sum) => acc + sum, 0);

  return (
    <Flex w="100%" direction="column" gap="1rem">
      <Flex
        w="100%"
        bg="#F6F6F8"
        px="10px"
        py="10px"
        borderRadius="8px"
        align="center"
        justify="space-between"
      >
        <ReusableText
          color="#667085"
          size={{ lg: "16px", md: "14px", base: "12px" }}
          weight="normal"
          text="Client Name"
        />
        <ReusableText
          color="#19191A"
          size={{ lg: "16px", md: "14px", base: "12px" }}
          weight="bold"
          text={invoice?.client?.client_name}
        />
      </Flex>
      <Flex
        w="100%"
        bg="#F6F6F8"
        px="10px"
        py="10px"
        borderRadius="8px"
        align="center"
        justify="space-between"
      >
        <ReusableText
          color="#667085"
          size={{ lg: "16px", md: "14px", base: "12px" }}
          weight="normal"
          text="Email Address"
        />
        <ReusableText
          color="#19191A"
          size={{ lg: "16px", md: "14px", base: "12px" }}
          weight="bold"
          text={invoice?.client?.email}
        />
      </Flex>

      <Flex
        w="100%"
        bg="#F6F6F8"
        px="10px"
        py="10px"
        borderRadius="8px"
        align="center"
        justify="space-between"
      >
        <ReusableText
          color="#667085"
          size={{ lg: "16px", md: "14px", base: "12px" }}
          weight="normal"
          text="Phone Number"
        />
        <ReusableText
          color="#19191A"
          size={{ lg: "16px", md: "14px", base: "12px" }}
          weight="bold"
          text={invoice?.client?.phone_number}
        />
      </Flex>

      <Flex
        w="100%"
        bg="#F6F6F8"
        px="10px"
        py="10px"
        borderRadius="8px"
        align="center"
        justify="space-between"
      >
        <ReusableText
          color="#667085"
          size={{ lg: "16px", md: "14px", base: "12px" }}
          weight="normal"
          text="Address"
        />
        <ReusableText
          color="#19191A"
          size={{ lg: "16px", md: "14px", base: "12px" }}
          weight="bold"
          text={invoice?.client?.address}
        />
      </Flex>
      <Flex
        borderTop="1px solid #ccc"
        align="center"
        justify="space-between"
        w="100%"
        pt="1rem"
      >
        <ReusableText
          text="Invoice"
          size={{ lg: "16px", md: "14px", base: "12px" }}
          weight="medium"
          color="var(--container_color)"
        />
        {false && (
          <Flex align="start" direction="column" gap="4px" w="30%">
            <ReusableText
              text="Maximum Spend"
              size={{ lg: "16px", md: "14px", base: "12px" }}
              weight="medium"
              color="var(--container_color)"
            />
            <InputGroup>
              <Input
                type="number"
                value={invoice?.maximum_spend}
                placeholder="Maximum Spend (20%)"
              />
              <InputRightAddon bg="white" children="%" />
            </InputGroup>
          </Flex>
        )}
      </Flex>
      <Fragment>
        {invoicesArr?.map((item, i) => (
          <Flex
            w="100%"
            key={i}
            align="center"
            direction={{ base: "row", md: "row", lg: "row" }}
            justify="space-between"
            gap={{ lg: "2rem", md: "1rem", base: "4px" }}
            position="relative"
          >
            <ReusableText
              color="var(--container_color)"
              text={+i + 1}
              align="center"
            />
            <FormControl w="60%">
              <Input
                size="md"
                variant="outline"
                border="1px solid #ccc"
                focusBorderColor="brand.900"
                borderRadius="8px"
                type="text"
                bg="white"
                color="#000"
                isReadOnly
                placeholder="Description"
                value={item?.description}
                // onBlur={onBlur}
                // onChange={(e) => onChange1(e, i)}
              />
            </FormControl>

            <FormControl w="30%">
              <InputGroup>
                <InputLeftAddon color="#000" children="₦" bg="white" />
                <Input
                  size="md"
                  bg="white"
                  color="#000"
                  variant="outline"
                  border="1px solid #ccc"
                  focusBorderColor="brand.900"
                  borderRadius="8px"
                  type="number"
                  placeholder="Amount"
                  value={item?.amount}
                  isReadOnly
                />
              </InputGroup>
            </FormControl>
          </Flex>
        ))}
      </Fragment>
      <InvoiceStat
        vat={vat}
        subTotal={subTotal}
        show={true}
        pending={invoice?.pending_balance}
        total={+totalAmount + +vat}
      />
      <Receipt receipt={invoice?.receipt} />
    </Flex>
  );
}
