import React, { useEffect, useState } from "react";
import ReusableModal from "../UI/ReusableModal";
import InputComponent from "../UI/Input";
import { Flex, Button, useToast } from "@chakra-ui/react";
import SelectComponent from "../UI/Select";
import TextareaComponent from "../UI/Textarea";
import FileComponent from "../UI/File";
import PreviewDocument from "../brief/create brief/PreviewDocument";
import { createLeave, getEmployee, listLeaveType } from "../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

export default function RequestLeave({ isOpen, onClose }) {
  const [files, setFiles] = useState([]);
  const toast = useToast();
  const [type, setType] = useState("");
  const [reason, setReason] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const { user } = useContext(AuthContext);

  const start = new Date(startDate);
  const end = new Date(endDate);

  const differenceInTime = end.getTime() - start.getTime();
  const daysRemaining = differenceInTime / (1000 * 3600 * 24);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getEmployee(user?.id);
        setList(response?.employee?.leave);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user.id]);

  async function requestHandler() {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const startDay = start.getDate();
    const startMonth = start.getMonth() + 1;
    const startYear = start.getFullYear();
    const startHour = start.getHours();
    const startMin = start.getMinutes();
    const startSec = start.getSeconds();

    const endDay = end.getDate();
    const endMonth = end.getMonth() + 1;
    const endYear = end.getFullYear();
    const endHour = end.getHours();
    const endMin = end.getMinutes();
    const endSec = end.getSeconds();

    const data = {
      leave_type: type,
      reason,
      no_of_days: daysRemaining,
      start_date: `${startYear}-${startMonth
        .toString()
        .padStart(2, 0)}-${startDay.toString().padStart(2, 0)} ${startHour
        .toString()
        .padStart(2, 0)}:${startMin.toString().padStart(2, 0)}:${startSec
        .toString()
        .padStart(2, 0)}`,
      end_date: `${endYear}-${endMonth.toString().padStart(2, 0)}-${endDay
        .toString()
        .padStart(2, 0)} ${endHour.toString().padStart(2, 0)}:${endMin
        .toString()
        .padStart(2, 0)}:${endSec.toString().padStart(2, 0)}`,
      "documents[]": files,
      department: user?.department,
    };

    if (!type || !reason || !endDate || !startDate) {
      toast({
        title: `Leave details missing`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (end < start) {
      toast({
        title: "Enter a valid due date",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await createLeave(data);
      setLoading(false);
      onClose();
      toast({
        title: `Leave Request Submitted`,
        description: `You have successfully requested for leave.`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      // onClose();
      setLoading(false);
      // toast({
      //   title: `Error`,
      //   description: `${
      //     error?.response?.data.message || "something went wrong"
      //   }`,
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      //   position: "top-right",
      // });
    }
  }

  return (
    <ReusableModal title="Leave Request Form" isOpen={isOpen} onClose={onClose}>
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
        <SelectComponent
          onChange={(e) => setType(e.value)}
          label="Type of Leave"
          options={list || []}
        />
        <TextareaComponent
          onChange={(e) => setReason(e.target.value)}
          label="Reason for Leave"
          placeholder="Enter a description..."
        />
        <FileComponent
          title="Attach Documents"
          onChange={(e) => {
            setFiles((prev) => [e.target.files[0], ...prev]);
          }}
        />
        <PreviewDocument setFiles={setFiles} files={files} />
        <span style={{ color: "var(--container_color)" }}>
          You currently have <b>{user?.leave_count} leave days</b>
        </span>
        <Flex align="center" justify="space-between" gap="2rem" w="100%">
          <InputComponent
            onChange={(e) => setStartDate(e.target.value)}
            type="datetime-local"
            label="Start Time"
          />
          <InputComponent
            onChange={(e) => setEndDate(e.target.value)}
            type="datetime-local"
            label="End Time"
          />
        </Flex>

        <Flex w="100%" align="end" justify="end">
          <Button
            onClick={requestHandler}
            variant="solid"
            bg="brand.900"
            color="white"
            isLoading={loading}
            loadingText="Processing"
            isDisabled={+user?.leave_count === 0}
          >
            Send
          </Button>
        </Flex>
      </Flex>
    </ReusableModal>
  );
}
