import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import AuthProvider from "./util/context";

const theme = extendTheme({
  colors: {
    brand: {
      // 100: "#C395BB",
      900: "var(--brand)",
    },
    primary: {
      // 100: "#B2B3B3",
      900: "#2BA2DA",
    },

    error: {
      100: "#ffcccb",
      900: "#FF3D46",
    },
    grey: {
      900: "#19191A",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
