import { Avatar, Divider, Flex, Heading } from "@chakra-ui/react";

function ReusableTransaction({ el }) {
  const invoice = el?.invoice && JSON.parse(el?.invoice);
  return (
    <Flex w="100%" align="center" justify="space-between">
      <Flex align="center" gap="1rem">
        <Avatar size="xs" name={el?.status} />
        <Flex align="start" direction="column" gap="2px">
          <Heading size="sm" color="#333" fontWeight="medium">
            {invoice[0]?.description}
          </Heading>
          <Heading
            textTransform="capitalize"
            size="xs"
            color="#333"
            fontWeight="normal"
          >
            {el?.status}
          </Heading>
        </Flex>
      </Flex>

      <Heading size="xs" color="#333" fontWeight="normal">
        {`₦${new Intl.NumberFormat().format(el?.grand_total)}`}
      </Heading>
    </Flex>
  );
}

export default function Budget({ brief }) {
  const spent = brief?.total_paid - brief?.available_balance;
  const maxSpend = brief?.maximum_spend_value;

  const percentage = spent / maxSpend;

  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      bg="white"
      gap="1rem"
      boxShadow="lg"
      borderRadius="16px"
      p="1rem"
    >
      <Flex w="100%" align="center" justify="space-between">
        <Heading size="sm" color="#333" fontWeight="bold">
          Funds Available
        </Heading>
      </Flex>
      <Divider />
      <Flex w="100%" align="start" direction="column" gap="8px">
        <Flex w="100%" align="center" justify="space-between">
          <Heading size="sm" color="#333" fontWeight="bold">
            {`₦${new Intl.NumberFormat().format(brief?.maximum_spend_value)}`}
          </Heading>
          <Heading size="sm" color="green.600" fontWeight="bold">
            {`₦${new Intl.NumberFormat().format(brief?.available_balance)}`}
          </Heading>
        </Flex>
        <Flex
          w="100%"
          height="10px"
          align="center"
          borderRadius={24}
          bg={percentage >= 0.7 ? "red.100" : "green.100"}
        >
          <Flex
            w={`${percentage * 100}%`}
            bg={percentage >= 0.7 ? "red.800" : "green.800"}
            borderRadius={24}
            h="10px"
          ></Flex>
        </Flex>
      </Flex>
      <Heading size="sm" color="#333" fontWeight="bold">
        Financial BreakDown
      </Heading>
      <Divider />
      <Flex w="100%" align="start" direction="column" gap="4px">
        <Flex w="100%" align="center" justify="space-between">
          <Heading size="xs" color="#333" fontWeight="medium">
            Available Balance:
          </Heading>
          <Heading size="xs" color="#333" fontWeight="medium">
            {`₦${new Intl.NumberFormat().format(brief?.available_balance)}`}
          </Heading>
        </Flex>

        <Flex w="100%" align="center" justify="space-between">
          <Heading size="xs" color="#333" fontWeight="medium">
            Total Amount Paid:
          </Heading>
          <Heading size="xs" color="#333" fontWeight="medium">
            {`₦${new Intl.NumberFormat().format(brief?.total_paid)}`}
          </Heading>
        </Flex>

        <Flex w="100%" align="center" justify="space-between">
          <Heading size="xs" color="#333" fontWeight="medium">
            Total Amount Spent:
          </Heading>
          <Heading size="xs" color="#333" fontWeight="normal">
            {`₦${new Intl.NumberFormat().format(spent)}`}
          </Heading>
        </Flex>
        <Flex w="100%" align="center" justify="space-between">
          <Heading size="xs" color="#333" fontWeight="medium">
            Pending Balance:
          </Heading>
          <Heading size="xs" color="#333" fontWeight="normal">
            {`₦${new Intl.NumberFormat().format(spent)}`}
          </Heading>
        </Flex>
      </Flex>
      <Divider />
      <Flex
        maxHeight="400px"
        overflow="scroll"
        w="100%"
        gap="1rem"
        align="start"
        direction="column"
      >
        {brief?.invoices?.map((el, i) => (
          <ReusableTransaction el={el} key={i} />
        ))}
      </Flex>
    </Flex>
  );
}
