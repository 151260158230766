import { Box, Flex } from "@chakra-ui/react";
import { BiFile } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import ReusableText from "../UI/ReusableText";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export default function DocumentDetail({ file, setShow }) {
  const modified = new Date(file.lastModified);

  const modifiedMonth = modified.getMonth() + 1;
  const modifiedDay = modified.getDate();
  const modifiedYear = modified.getFullYear();
  const modifiedHour = modified.getHours();
  const modifiedMinute = modified.getMinutes();

  const { user } = useContext(AuthContext);

  return (
    <Flex
      w="20%"
      borderLeft="1px solid #ccc"
      align="center"
      direction="column"
      // justify="center"
      gap="1rem"
      p="1rem"
      h="75vh"
      position="relative"
    >
      <BiFile cursor="pointer" fontSize="100px" />
      <Box onClick={() => setShow(false)} position="absolute" top="0" right="0">
        <AiOutlineClose color="red" fontSize="1rem" cursor="pointer" />
      </Box>
      <ReusableText
        text={file.name}
        size="18px"
        color="#3F434A"
        weight="medium"
        align="center"
      />
      <Flex w="100%" align="start" direction="column" gap="1rem">
        <ReusableText
          text="Information"
          size="16px"
          color="#3F434A"
          weight="medium"
          align="start"
        />
        <Flex w="100%" align="center" gap="2rem">
          <ReusableText
            text="Type"
            align="start"
            size="16px"
            weight="normal"
            color="#595F69"
          />
          <ReusableText
            text={file.type.slice(0, 20)}
            align="start"
            size="14px"
            weight="light"
            color="#8A9099"
          />
        </Flex>
        <Flex w="100%" align="center" gap="2rem">
          <ReusableText
            text="Size"
            align="start"
            size="16px"
            weight="normal"
            color="#595F69"
          />
          <ReusableText
            text={file.size}
            align="start"
            size="14px"
            weight="light"
            color="#8A9099"
          />
        </Flex>
        <Flex w="100%" align="center" gap="2rem">
          <ReusableText
            text="Owner"
            align="start"
            size="16px"
            weight="normal"
            color="#595F69"
          />
          <ReusableText
            text={`${user?.first_name} ${user?.last_name}`}
            align="start"
            size="14px"
            weight="light"
            color="#8A9099"
          />
        </Flex>
        <Flex w="100%" align="center" gap="2rem">
          <ReusableText
            text="Location"
            align="start"
            size="16px"
            weight="normal"
            color="#595F69"
          />
          <ReusableText
            text="My Files"
            align="start"
            size="14px"
            weight="light"
            color="#8A9099"
          />
        </Flex>
        <Flex w="100%" align="center" gap="2rem">
          <ReusableText
            text="Modified"
            align="start"
            size="16px"
            weight="normal"
            color="#595F69"
          />
          <ReusableText
            text={`${
              months[file.lastModifiedDate.getMonth() + 1]
            } ${file.lastModifiedDate
              .getDate()
              .toString()
              .padStart(
                2,
                0
              )} ${file.lastModifiedDate.getFullYear()} ${file.lastModifiedDate
              .getHours()
              .toString()
              .padStart(2, 0)}:${file.lastModifiedDate
              .getMinutes()
              .toString()
              .padStart(2, 0)}`}
            align="start"
            size="14px"
            weight="light"
            color="#8A9099"
          />
        </Flex>{" "}
        <Flex w="100%" align="center" gap="2rem">
          <ReusableText
            text="Created"
            align="start"
            size="16px"
            weight="normal"
            color="#595F69"
          />
          <ReusableText
            text={`${months[modifiedMonth]} ${modifiedDay
              .toString()
              .padStart(2, 0)} ${modifiedYear} ${modifiedHour
              .toString()
              .padStart(2, 0)}:${modifiedMinute.toString().padStart(2, 0)}`}
            align="start"
            size="14px"
            weight="light"
            color="#8A9099"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
