import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
} from "@chakra-ui/react";

export default function InputComponent({
  label,
  type,
  value,
  onChange,
  placeholder,
  readOnly,
  border,
  p,
  required = false,
  bg,
  br,
  round,
}) {
  return (
    <FormControl isRequired={required}>
      <FormLabel fontWeight="medium" color="var(--container_color)">
        {label}
      </FormLabel>
      <Input
        readOnly={readOnly ? readOnly : false}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        focusBorderColor="brand.900"
        border={border ? border : "1px solid #ccc"}
        p={p ? p : ""}
        bg={bg ? bg : "white"}
        borderRight={br}
        borderRadius={round}
        color="#000"
      />
      {false && <FormHelperText>We'll never share your email.</FormHelperText>}
    </FormControl>
  );
}
