import { Button } from "react-chat-engine";
import ReusableModal from "../../../UI/ReusableModal";
import SelectComponent from "../../../UI/Select";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";

export default function UpdateMilestone({ isOpen, onClose }) {
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function updateHandler() {
    const data = {
      status,
    };

    if (!status) {
      toast({
        title: `Select a status`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      //   await updateTimeline(data, id);
      window.location.reload();
      toast({
        title: `Milestone updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `${error?.response?.data.message || "something went wrong"}`,
        description: ``,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <ReusableModal title="Update Timeline" isOpen={isOpen} onClose={onClose}>
      <SelectComponent
        label="Select Status"
        onChange={(e) => setStatus(e.value)}
        options={["Pending", "In Review", "Completed"]}
      />
      <Button
        isLoading={loading}
        loadingText=""
        onClick={updateHandler}
        bg="brand.900"
        color="white"
        variant="solid"
        w="100%"
      >
        Save Update
      </Button>
    </ReusableModal>
  );
}
