import { Flex } from "@chakra-ui/react";
import React from "react";
import FileItem from "./FileItem";

export default function FileList({ list, show }) {
  return (
    <Flex align="start" justify="start" wrap="wrap" gap="1rem" w="100%">
      {list?.length > 0 ? (
        <>
          {list?.map((item, i) => (
            <FileItem showMenu={show} key={i} item={item} />
          ))}
        </>
      ) : (
        <Flex
          color="var(--container_color)"
          w="100%"
          align="center"
          justify="center"
          h="300px"
        >
          No file found...
        </Flex>
      )}
    </Flex>
  );
}
