import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { Fragment, useContext, useEffect } from "react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import { AddIcon } from "@chakra-ui/icons";
import ClientDetail from "./ClientDetail";
import CreateReceipt from "./CreateReceipt";
import DownloadPage from "../../UI/DownloadPage";
import { useRef } from "react";
import InvoiceTemplate from "../../UI/InvoiceTemplate";
import LoadingSkeleton from "../../UI/LoadingSkeleton";
import { useState } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { getStoredItem } from "../../../util/lib";
import { getVAT } from "../../../util/http";
import { AuthContext } from "../../../util/context";

export default function ViewInvoiceContainer({ invoice, loading }) {
  const docRef = useRef();
  const userType = getStoredItem("user-type");

  const pendingBalance = invoice?.pending_balance;
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [vatVal, setVatVal] = useState(0);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getVAT();

        setVatVal(+response?.vat);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const invoiceArr = invoice?.invoice && JSON.parse(invoice.invoice);
  const grandTotal = invoiceArr
    ?.map((el, i) => {
      return { amount: el.vat ? el.amount * vatVal + el.amount : el.amount };
    })
    .map((el) => el.amount)
    .reduce((acc, sum) => acc + sum, 0);

  const total = invoiceArr
    ?.map((el) => +el.amount)
    .reduce((acc, sum) => acc + sum, 0);

  const vat = grandTotal - total;

  function download() {
    setShow(true);
  }

  function showCreate() {
    setShow1(true);
  }

  const canCreate =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop";

  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title={`Invoice No. ${invoice?.invoice_id}`}
          showDottedMenu={true}
          text=""
          show={userType === "employee" && !canCreate ? true : false}
          btnText={userType === "employee" && !canCreate && "Create Receipt"}
          icon={userType === "employee" && !canCreate && <AddIcon />}
          onClick={userType === "employee" && !canCreate && showCreate}
          menu={[
            {
              name: "Download",
              icon: "",
              navigation: false,
              onClick: download,
            },
          ]}
        />

        {show && (
          <>
            <Flex align="center" w="100%" justify="space-between">
              <DownloadPage element={docRef} name="invoice" setShow={setShow} />
              <Button colorScheme="red" onClick={() => setShow(false)}>
                <AiOutlineClose />
              </Button>
            </Flex>
            <Flex align="center" ref={docRef} w="100%" justify="center">
              <InvoiceTemplate
                total={total}
                vat={vat}
                grandTotal={grandTotal}
                pending={pendingBalance}
                invoice={invoiceArr}
                client={invoice?.client}
                id={`${invoice?.invoice_id}`}
              />
            </Flex>
          </>
        )}
        {loading ? (
          <LoadingSkeleton lines={20} />
        ) : (
          <ClientDetail invoice={invoice} />
        )}
      </Flex>
      {show1 && <CreateReceipt invoice={invoice} show={setShow1} />}
    </Fragment>
  );
}
