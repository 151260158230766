import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, { Fragment, useState } from "react";
import { BiFile } from "react-icons/bi";
import { BsFiletypePng, BsFiletypeDocx } from "react-icons/bs";
import ReusableText from "../UI/ReusableText";
import ShareDocument from "./ShareDocument";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineFileJpg, AiFillFilePdf } from "react-icons/ai";
import { deleteDocument } from "../../util/http";

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default function FileItem({ item, showMenu = true }) {
  const newArr = item?.path?.split("/");
  newArr?.splice(0, 3);
  const newPath = newArr?.join("/");

  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState({
    id: "",
    origin: "",
  });
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  async function deleteHandler() {
    const data = {
      filename: item?.file_name,
      origin: item?.origin,
    };

    try {
      setLoading(true);
      await deleteDocument(data);
      toast({
        title: `${item?.file_name} deleted`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      window.location.reload();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Fragment>
      {show && <ShareDocument detail={detail} show={setShow} />}

      <Flex
        borderBottom="1px solid #ccc"
        w="20%"
        bg="white"
        boxShadow="lg"
        borderRadius={16}
        direction="column"
        align="center"
        justify="space-between"
        py="1rem"
        px="8px"
        cursor="pointer"
      >
        <Flex w="100%" align="start" justify="space-between">
          <Menu>
            <MenuButton bg="transparent" as={Button} cursor="pointer">
              <BsThreeDotsVertical />
            </MenuButton>
            <MenuList>
              <MenuItem>
                <a
                  href={`https://${newPath}`}
                  download={item?.file_name}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </a>
              </MenuItem>
              {showMenu && (
                <MenuItem
                  onClick={() => {
                    setDetail({ id: item?.id, origin: item.origin });
                    setShow(true);
                  }}
                >
                  Share
                </MenuItem>
              )}
              {showMenu && <MenuItem onClick={deleteHandler}>Delete</MenuItem>}
            </MenuList>
          </Menu>
        </Flex>
        <Flex align="center" direction="column" gap="1rem">
          {item?.extension?.toLowerCase() === "jpg" ||
          item?.extension?.toLowerCase() === "jpeg" ? (
            <AiOutlineFileJpg cursor="pointer" fontSize="40px" color="orange" />
          ) : item?.extension?.toLowerCase() === "png" ? (
            <BsFiletypePng cursor="pointer" fontSize="40px" color="green" />
          ) : item?.extension?.toLowerCase() === "pdf" ? (
            <AiFillFilePdf cursor="pointer" fontSize="40px" color="red" />
          ) : item?.extension?.toLowerCase() === "docx" ? (
            <BsFiletypeDocx cursor="pointer" fontSize="40px" color="blue" />
          ) : (
            <BiFile cursor="pointer" fontSize="40px" color={getRandomColor()} />
          )}
          <ReusableText
            text={item?.file_name?.slice(0, 20)}
            size="16px"
            color="#3F434A"
            weight="medium"
            title={item?.file_name}
          />
        </Flex>
      </Flex>
    </Fragment>
  );
}
