import { Button, Flex, useToast } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import ReusableText from "../../UI/ReusableText";
import ReusableSupportingDocuments from "../../UI/ReusableSupportingDocuments";
import TextareaComponent from "../../UI/Textarea";
import SelectComponent from "../../UI/Select";
import { approveLeave, updateLeave } from "../../../util/http";
import { AuthContext } from "../../../util/context";

export default function LeaveDetailCenter({ leave }) {
  const toast = useToast();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  const { user } = useContext(AuthContext);
  const isAllowed =
    user?.job_role?.toLowerCase() !== "lawyer" &&
    user?.job_role?.toLowerCase() !== "user";

  async function approveLeaveHandler() {
    const data = {
      decision: status,
    };

    if (!status) {
      return;
    }

    try {
      setLoading(true);
      const response = await approveLeave(leave?.leave_id, data);
      console.log(response);
      toast({
        title: `Leave decision updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function updateLeaveHandler() {
    const data = {
      comment,
    };

    if (!comment) {
      toast({
        title: `Add a comment`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await updateLeave(leave?.leave_id, data);
      window.location.reload();
      toast({
        title: `Comment added`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Flex
      py="4rem"
      borderTop="1px solid #ccc"
      w="100%"
      direction="column"
      align="start"
      gap="2rem"
    >
      <Flex w="50%" align="start" direction="column" gap="1rem">
        <ReusableText
          text="Full Name"
          size="14px"
          color="var(--gray)"
          weight="medium"
        />
        <ReusableText
          text={leave?.employee}
          size={{ lg: "18px", md: "16px", base: "13px" }}
          color="var(--light_gray)"
          weight="medium"
        />
      </Flex>
      <Flex w="100%" align="center" justify="space-between">
        <Flex w="50%" align="start" direction="column" gap="1rem">
          <ReusableText
            text="Type of leave"
            size="14px"
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={leave?.leave_type}
            size={{ lg: "18px", md: "16px", base: "13px" }}
            color="var(--light_gray)"
            weight="medium"
          />
        </Flex>

        <Flex w="50%" align="start" direction="column" gap="1rem">
          <ReusableText
            text="Number of days"
            size="14px"
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={leave?.no_of_days}
            size={{ lg: "18px", md: "16px", base: "13px" }}
            color="var(--light_gray)"
            weight="medium"
          />
        </Flex>
      </Flex>

      <Flex w="100%" align="center" justify="space-between">
        <Flex w="50%" align="start" direction="column" gap="1rem">
          <ReusableText
            text="State date"
            size="14px"
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={`${new Date(
              leave?.start_date
            ).toLocaleDateString()} ${new Date(
              leave?.start_date
            ).toLocaleTimeString()}`}
            size={{ lg: "18px", md: "16px", base: "13px" }}
            color="var(--light_gray)"
            weight="medium"
          />
        </Flex>

        <Flex w="50%" align="start" direction="column" gap="1rem">
          <ReusableText
            text="End date"
            size="14px"
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={`${new Date(leave?.end_date).toLocaleDateString()} ${new Date(
              leave?.end_date
            ).toLocaleTimeString()}`}
            size={{ lg: "18px", md: "16px", base: "13px" }}
            color="var(--light_gray)"
            weight="medium"
          />
        </Flex>
      </Flex>
      <Flex w="100%" align="center" justify="space-between">
        <Flex w="50%" align="start" direction="column" gap="1rem">
          <ReusableText
            text="Reason for leave"
            size="14px"
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={leave?.reason}
            size={{ lg: "18px", md: "16px", base: "13px" }}
            color="var(--light_gray)"
            weight="medium"
            w="80%"
          />
        </Flex>

        {false && (
          <Flex w="50%" align="start" direction="column" gap="1rem">
            <ReusableText
              text="Remaining leave days"
              size="14px"
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={`${user?.leave_count} days remaining`}
              size={{ lg: "18px", md: "16px", base: "13px" }}
              color="green.600"
              weight="bold"
              w="80%"
            />
          </Flex>
        )}
      </Flex>

      <Flex w={{ lg: "60%", md: "100%", base: "100%" }}>
        <ReusableSupportingDocuments docs={leave?.documents} />
      </Flex>

      <Flex w="100%" align="center" gap="1rem">
        <Flex w="100%" align="start" direction="column" gap="4px">
          <ReusableText
            text="HOD Decision"
            size={{ lg: "14px", md: "12px", base: "10px" }}
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={leave?.hod_decision || "Pending"}
            size={{ lg: "16px", md: "14px", base: "12px" }}
            color="var(--light_gray)"
            weight="medium"
          />
        </Flex>

        <Flex w="100%" align="start" direction="column" gap="4px">
          <ReusableText
            text="HOP Decision"
            size={{ lg: "14px", md: "12px", base: "10px" }}
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={leave?.hop_decision || "Pending"}
            size={{ lg: "16px", md: "14px", base: "12px" }}
            color="var(--light_gray)"
            weight="medium"
          />
        </Flex>
      </Flex>

      <Flex w="100%" align="center" gap="1rem">
        <Flex w="100%" align="start" direction="column" gap="4px">
          <ReusableText
            text="HR Decision"
            size={{ lg: "14px", md: "12px", base: "10px" }}
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={leave?.hr_decision || "Pending"}
            size={{ lg: "16px", md: "14px", base: "12px" }}
            color="var(--light_gray)"
            weight="medium"
          />
        </Flex>

        <Flex w="100%" align="start" direction="column" gap="4px">
          <ReusableText
            text="MANAGEMENT Decision"
            size={{ lg: "14px", md: "12px", base: "10px" }}
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={leave?.decision || "Pending"}
            size={{ lg: "16px", md: "14px", base: "12px" }}
            color="var(--light_gray)"
            weight="medium"
          />
        </Flex>
      </Flex>

      <Flex
        align="start"
        direction="column"
        gap="1rem"
        maxHeight="50vh"
        overflow="scroll"
        w="100%"
        bg="#eee"
        p="4"
      >
        {leave?.comments?.map((el, i) => (
          <Flex w="100%" key={i} align="start" direction="column" gap="1rem">
            <ReusableText
              text={el.employee}
              size="14px"
              color="var(--gray)"
              weight="medium"
              transform={true}
            />
            <ReusableText
              text={el.comment}
              size={{ lg: "18px", md: "16px", base: "13px" }}
              color="var(--light_gray)"
              weight="medium"
              w="80%"
              transform={true}
            />
          </Flex>
        ))}
      </Flex>

      {isAllowed && (
        <Flex
          align="start"
          direction="column"
          gap="1.5rem"
          w={{ lg: "60%", md: "100%", base: "100%" }}
        >
          <TextareaComponent
            onChange={(e) => setComment(e.target.value)}
            label="comment"
            placeholder="Enter a description..."
          />
          <Button
            onClick={() => {
              updateLeaveHandler();
            }}
            isLoading={loading}
            loadingText="Processing"
            bg="green.600"
            color="white"
            variant="solid"
          >
            Add Comment
          </Button>
          <SelectComponent
            label="Select Decision"
            options={["Approve", "Reject"]}
            onChange={(e) => setStatus(e.value)}
          />
        </Flex>
      )}
      {isAllowed && (
        <Flex align="center" w="100%" justify="space-between">
          {isAllowed && (
            <Button
              onClick={() => {
                approveLeaveHandler();
              }}
              isLoading={loading}
              loadingText="Processing"
              bg="green.400"
              color="white"
              variant="solid"
              isDisabled={leave?.decision?.toLowerCase() === "approved"}
            >
              Approve/Deny Leave
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );
}
