import { AddIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  Button,
  Box,
  Badge,
  Avatar,
  useDisclosure,
} from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import AddMilestone from "./AddMilestone";
import UpdateMilestone from "./UpdateMilestone";
import { useState } from "react";

function ReusableActivity({ el }) {
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  return (
    <>
      <UpdateMilestone id={id} isOpen={isOpen} onClose={onClose} />
      <Flex w="100%" align="start" direction="column">
        <Flex w="100%" align="center" justify="space-between">
          <Flex align="start" gap="1rem">
            <Box
              w="20px"
              h="20px"
              borderRadius="100%"
              bg={getRandomColor()}
            ></Box>
            <Flex align="start" direction="column" gap="8px">
              <Heading
                textTransform="capitalize"
                size="sm"
                color="#333"
                fontWeight="bold"
              >
                Go to court today
              </Heading>
              <Flex align="center" gap="0.5rem">
                <Avatar size="xs" name="michael johnson" />
                <Heading
                  textTransform="capitalize"
                  size="sm"
                  color="#333"
                  fontWeight="bold"
                >
                  michael johnson
                </Heading>
              </Flex>
            </Flex>
          </Flex>
          <Heading size="xs" color="#333" fontWeight="normal">
            {new Date(el?.start_date).toLocaleDateString()} -{" "}
            {new Date(el?.end_date).toLocaleDateString()}
          </Heading>
        </Flex>
        <Flex w="100%" align="center" justify="space-between">
          <Heading
            textTransform="capitalize"
            size="xs"
            color="#333"
            fontWeight="normal"
          >
            description here
          </Heading>
          <Flex align="center" gap="1rem">
            <Badge
              colorScheme={
                el?.status?.toLowerCase() === "pending"
                  ? "gray"
                  : el?.status?.toLowerCase() === "completed"
                  ? "green"
                  : el?.status?.toLowerCase() === "draft"
                  ? "red"
                  : "yellow"
              }
            >
              pending
            </Badge>
            <Button
              onClick={() => {
                // setId(el?.id);
                onOpen();
              }}
              variant="ghost"
              colorScheme="blue"
            >
              <FiEdit color="blue" />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default function TaskTimeline({ task }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <AddMilestone task={task} isOpen={isOpen} onClose={onClose} />
      <Flex
        w="100%"
        align="start"
        direction="column"
        bg="white"
        gap="1rem"
        boxShadow="lg"
        borderRadius="16px"
        p="1rem"
      >
        <Heading size="md" color="#333" fontWeight="bold">
          Task Milestone
        </Heading>
        <Flex
          w="100%"
          align="start"
          direction="column"
          gap="1rem"
          maxHeight="400px"
          overflow="scroll"
        >
          {[1, 1, 1, 1, 1, 1]?.length > 0 ? (
            <>
              {[1, 1, 1, 1, 1, 1]?.map((el, i) => (
                <ReusableActivity
                  el={el}
                  key={i}
                  // setShow={setShow1}
                  // setId={setId}
                />
              ))}
            </>
          ) : (
            <Flex w="100%" align="center" justify="center" h="20vh">
              Empty Timeline...
            </Flex>
          )}
        </Flex>

        <Flex align="end" justify="end" w="100%">
          <Button
            leftIcon={<AddIcon />}
            onClick={onOpen}
            variant="solid"
            bg="#364273"
            color="white"
          >
            Add Milestone
          </Button>
        </Flex>
      </Flex>
    </>
  );
}
