import { useContext, useState } from "react";
import ReusableModal from "../UI/ReusableModal";
import SelectComponent from "../UI/Select";
import TextareaComponent from "../UI/Textarea";
import { Flex, Button, useToast } from "@chakra-ui/react";
import { createRequisitions, getBriefs } from "../../util/http";
import { useEffect } from "react";
import ReusableAmountInput from "../UI/ReusableAmountInput";
import { AuthContext } from "../../util/context";

export default function CreateRequisition({ isOpen, onClose }) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [level, setLevel] = useState("");
  const [title, setTitle] = useState("");
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState("");
  const [type, setType] = useState("client & brief related");
  const [disbursement, setDisbursement] = useState("");
  const [briefTitles, setBriefTitles] = useState([]);
  const [allBriefs, setAllBriefs] = useState([]);
  const [briefId, setBriefId] = useState("");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const briefTitle = allBriefs.map((el) => el.name);
    setBriefTitles(briefTitle);
  }, [allBriefs]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response2 = await getBriefs();
        const filteredBrief = response2.briefs?.filter(
          (el, i) =>
            el.status?.toLowerCase() === "active" &&
            el?.brief_type?.toLowerCase() === user?.department?.toLowerCase()
        );
        const newBriefs = filteredBrief?.map((el) => {
          return {
            name: el.brief_title,
            id: el.brief_id,
            type: el.brief_type,
            client: el.client_id,
          };
        });
        setAllBriefs(newBriefs);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user]);

  async function createRequisitionHandler() {
    const brief_id = allBriefs?.find((el) => el.name == title)?.id;
    const brief_type = allBriefs.find((el) => el.name == title)?.type;
    const client_id = allBriefs.find((el) => el.name == title)?.client;

    const data = {
      priority_level: level,
      brief_title: title,
      brief_type,
      brief_id,
      client_id,
      reason,
      amount: +amount,
      disburse_method: disbursement,
      requisition_type: type,
    };

    if (!level || !title || !amount || !disbursement) {
      toast({
        title: "Requisition details missing",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await createRequisitions(data);
      setLoading(false);
      onClose();
      toast({
        title: `Requisition Submitted`,
        description: `You have successfully submit a requisition`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }
  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Requisition">
      <Flex py="1rem" w="100%" gap="1.5rem" direction="column" align="center">
        <SelectComponent
          onChange={(e) => setType(e.value.toLowerCase())}
          label="Requisition Type"
          options={[
            "Select Requisition Type",
            "Internal",
            "Client & Brief Related",
          ]}
        />
        <SelectComponent
          onChange={(e) => setLevel(e.value)}
          label="Priority Level"
          options={[
            "Select Priority level",
            "Normal",
            "Urgent",
            "Important",
            "Urgent & Important",
          ]}
        />
        {type.toLowerCase() !== "internal" && (
          <SelectComponent
            onChange={(e) => setTitle(e.value)}
            label="Brief Title"
            options={["Select Brief Title", ...briefTitles]}
          />
        )}
        <TextareaComponent
          onChange={(e) => setReason(e.target.value)}
          label="Reason for Requisition"
          placeholder="Enter a description..."
        />
        <ReusableAmountInput
          label="Enter Requisition Amount"
          placeholder="₦ Type amount here"
          onChange={(e) => setAmount(e.target.rawValue)}
        />

        <SelectComponent
          onChange={(e) => setDisbursement(e.value)}
          label="Means of Disbursement"
          options={[
            "Select the means of disbursement",
            "Cash",
            "Transfer",
            "Cheque",
          ]}
        />
        <Flex w="100%" align="end" justify="end">
          <Button
            onClick={createRequisitionHandler}
            variant="solid"
            bg="brand.900"
            color="white"
            isLoading={loading}
            loadingText="Processing"
          >
            Submit
          </Button>
        </Flex>
      </Flex>
    </ReusableModal>
  );
}
