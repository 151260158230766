import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ImageComponent from "./Image";
import logo from "../images/logo.png";
import ReusableText from "./ReusableText";

export default function InvoiceTemplate({
  id,
  invoice,
  client,
  total,
  vat,
  grandTotal,
  pending,
}) {
  return (
    <Flex align="start" p="8px" direction="column" gap-4 w="100%">
      <Flex
        align="center"
        px="1rem"
        py="8px"
        borderBottom="1px solid #ccc"
        justify="space-between"
        w="100%"
      >
        <Box w="50px">
          <ImageComponent src={logo} />
        </Box>
        <ReusableText
          size="20px"
          weight="bold"
          color="var(--container_color)"
          text="Invoice"
        />
      </Flex>
      <Flex align="start" py="8px" w="100%" direction="column" gap="0.5rem">
        <Flex align="center" gap="1rem">
          <ReusableText
            text="Invoice Number:"
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText text={id} color="var(--container_color)" />
        </Flex>

        <Flex align="center" gap="1rem">
          <ReusableText
            text="Invoice Date:"
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText
            text={new Date().toLocaleDateString()}
            color="var(--container_color)"
          />
        </Flex>
      </Flex>

      <Flex
        align="start"
        px={{ lg: "2rem", md: "1rem", base: "6px" }}
        mt="2rem"
        direction="column"
        w="100%"
      >
        <Flex
          align="center"
          py="4px"
          borderBottom="1px solid #ccc"
          justify="space-between"
          w="100%"
        >
          <ReusableText
            text="Client Name"
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText
            text={client?.client_name}
            transform={true}
            color="var(--container_color)"
          />
        </Flex>

        <Flex
          align="center"
          py="4px"
          borderBottom="1px solid #ccc"
          justify="space-between"
          w="100%"
        >
          <ReusableText
            text="Client Email"
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText text={client?.email} color="var(--container_color)" />
        </Flex>
      </Flex>
      <ReusableText
        text="Invoice Detail"
        size={{ lg: "24px", md: "18px", base: "16px" }}
        color="var(--container_color)"
        weight="bold"
        align="start"
      />
      <Flex
        align="start"
        px={{ lg: "2rem", md: "1rem", base: "6px" }}
        mt="2rem"
        direction="column"
        w="100%"
      >
        {invoice?.map((el, i) => (
          <Flex
            key={i}
            align="center"
            // py="1rem"
            borderBottom="1px solid #ccc"
            justify="space-between"
            w="100%"
          >
            <ReusableText
              text={el?.description}
              color="var(--container_color)"
              weight="bold"
            />
            <ReusableText text={el.amount} color="var(--container_color)" />
          </Flex>
        ))}
      </Flex>
      <Flex
        p="1rem"
        align="end"
        justify="end"
        w="100%"
        direction="column"
        gap="1rem"
      >
        <Flex align="center" gap="1rem">
          <ReusableText
            size="20px"
            text="Total:"
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText
            size="18px"
            text={`₦${new Intl.NumberFormat().format(total)}.00`}
            color="var(--container_color)"
          />
        </Flex>
        <Flex align="center" gap="1rem">
          <ReusableText
            size="20px"
            text="VAT:"
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText
            size="18px"
            text={`₦${new Intl.NumberFormat().format(vat)}.00`}
            color="var(--container_color)"
          />
        </Flex>
        <Flex align="center" gap="1rem">
          <ReusableText
            size="20px"
            text="Grand Total:"
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText
            size="18px"
            text={`₦${new Intl.NumberFormat().format(grandTotal)}.00`}
            color="var(--container_color)"
          />
        </Flex>
        <Flex align="center" gap="1rem">
          <ReusableText
            size="20px"
            text="Pending Balance:"
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText
            size="18px"
            text={`₦${new Intl.NumberFormat().format(pending)}.00`}
            color="red.400"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
