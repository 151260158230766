import { Flex } from "@chakra-ui/react";
import ReusableText from "../../../UI/ReusableText";
import { Link } from "react-router-dom";
import BriefTitle from "../../../brief/BriefTitle";
import BriefList from "../../../brief/BriefList";

export default function RecentBriefs({ list }) {
  return (
    <Flex w="100%" direction="column">
      <Flex py="1rem" align="center" w="100%" justify="space-between">
        <ReusableText
          text="Recent Briefs"
          color="var(--container_color)"
          size="18px"
          weight="medium"
        />
        <Link to="/brief" style={{}}>
          View all {">"}
        </Link>
      </Flex>
      <BriefTitle />
      <BriefList list={list?.slice(0, 7)} />
    </Flex>
  );
}
