import { Flex, Box } from "@chakra-ui/react";
import { FormControl, FormLabel, Select, Input } from "@chakra-ui/react";
import InputComponent from "../../UI/Input";
import SelectComponent from "../../UI/Select";
import ReusableText from "../../UI/ReusableText";
import { useEffect } from "react";
import { getCountries, getState } from "../../../util/http";
import { useState } from "react";

export default function ContactPerson({
  title,
  setKinFirstName,
  setKinLastName,
  setKinEmail,
  setKinPhone,
  setKinAddress1,
  setKinAddress2,
  setKinCity,
  setKinState,
  setKinCountry,
  setKinCode,
  country,
}) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getCountries();
        const countryList = response?.map((el) => el.country);
        setCountries(countryList);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchState() {
      if (!country) {
        return;
      }
      try {
        const response = await getState({ country });
        setStates(response?.states?.map((el) => el.name));

        console.log(states);
      } catch (error) {
        console.log(error);
      }
    }
    fetchState();
  }, [country]);
  return (
    <Flex
      align="center"
      height={{ lg: "550px", md: "100%", base: "100%" }}
      borderBottom="1px solid #ccc"
      justify="space-between"
      w="100%"
      direction={{ lg: "row", base: "column", md: "column" }}
    >
      <Box
        w={{ lg: "30%", md: "100%", base: "100%" }}
        h={{ lg: "500px", md: "100%", base: "100%" }}
      >
        <ReusableText
          text={title ? title : "Contact Person"}
          size="20px"
          weight="600"
          color="var(--container_color)"
        />
      </Box>

      <Flex
        h={{ lg: "500px", md: "100%", base: "100%" }}
        align="center"
        direction="column"
        gap="2rem"
        w="100%"
      >
        <Flex align="center" w="100%" justify="center" gap="1rem">
          <InputComponent
            placeholder="Enter first name"
            label="First Name"
            type="text"
            onChange={(e) => setKinFirstName(e.target.value)}
          />
          <InputComponent
            label="Last Name"
            placeholder="Enter last name"
            type="text"
            onChange={(e) => setKinLastName(e.target.value)}
          />
        </Flex>
        <Flex align="center" w="100%" justify="center" gap="1rem">
          <InputComponent
            placeholder="Enter email address"
            label="Email"
            type="email"
            onChange={(e) => setKinEmail(e.target.value)}
          />
          <FormControl>
            <FormLabel>Phone Number</FormLabel>
            <Flex border="1px solid #ccc" borderRadius="0.5rem" align="center">
              <Select border="none" w="30%">
                <option value="234">+234</option>
              </Select>
              <Input
                type="number"
                border="none"
                onChange={(e) => setKinPhone(e.target.value)}
                placeholder="XXX XXX XXXX"
                focusBorderColor="transparent"
              />
            </Flex>
          </FormControl>
        </Flex>
        <Flex align="center" w="100%" justify="center" gap="1rem">
          <InputComponent
            placeholder="Enter address"
            label="Address Line 1"
            type="text"
            onChange={(e) => setKinAddress1(e.target.value)}
          />
          <InputComponent
            label="Address Line 2"
            placeholder="Enter address"
            type="text"
            onChange={(e) => setKinAddress2(e.target.value)}
          />
        </Flex>
        <Flex align="center" w="100%" justify="center" gap="1rem">
          <InputComponent
            onChange={(e) => setKinCity(e.target.value)}
            placeholder="Enter City"
            label="City"
            type="text"
          />
          <SelectComponent
            onChange={(e) => setKinCountry(e.value)}
            options={["Select Country", ...countries]}
            label="Country"
          />
        </Flex>

        <Flex align="center" w="100%" justify="center" gap="1rem">
          <SelectComponent
            onChange={(e) => setKinState(e.value)}
            options={["Select State", ...states]}
            label="State"
          />
          <InputComponent
            onChange={(e) => setKinCode(e.target.value)}
            placeholder="Enter Postal Code"
            label="Postal Code"
            type="text"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
