import { Button, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import React, { Fragment, useContext, useState } from "react";
import ReusableText from "../../UI/ReusableText";
import RequisitionDetail from "../RequisitionDetail";
import { sendBuzz, updateRequisition } from "../../../util/http";

import { AiOutlineBell } from "react-icons/ai";
import { AuthContext } from "../../../util/context";

export default function ViewDetail({ requisition }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);

  const isAllowed =
    // user?.job_role?.toLowerCase() === "hr" ||
    // user?.job_role?.toLowerCase() === "accountant" ||
    // user?.job_role?.toLowerCase() === "partner" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "seed";

  async function updateStatus() {
    const data = {
      status: "4",
    };

    try {
      setLoading(true);
      await updateRequisition(requisition?.requisition_id, data);
      window.location.reload();
      onClose();
      toast({
        title: `Update Successful`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      onClose();
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function sendBuzzHandler() {
    try {
      setLoading(true);
      await sendBuzz(requisition?.requisition_id);
      toast({
        title: `Reminder sent`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast({
      //   title: `Error`,
      //   description: `${
      //     error?.response?.data.message || "something went wrong"
      //   }`,
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      //   position: "top-right",
      // });
    }
  }

  return (
    <Fragment>
      <Flex my="2rem" align="center" w="100%" direction="column" gap="2rem">
        <Flex w="100%" align="start" direction="column" gap="4px">
          <ReusableText
            text="Brief Title"
            size={{ lg: "14px", md: "12px", base: "10px" }}
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={requisition?.brief_title}
            size={{ lg: "16px", md: "14px", base: "12px" }}
            color="var(--light_gray)"
            weight="medium"
          />
        </Flex>

        <Flex w="100%" align="start" direction="column" gap="4px">
          <ReusableText
            text="Amount Requested"
            size={{ lg: "14px", md: "12px", base: "10px" }}
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={`₦${new Intl.NumberFormat().format(requisition?.amount)}.00`}
            size={{ lg: "16px", md: "14px", base: "12px" }}
            color="var(--light_gray)"
            weight="medium"
          />
        </Flex>

        <Flex w="100%" align="start" direction="column" gap="4px">
          <ReusableText
            text="Reason for Requisition"
            size={{ lg: "14px", md: "12px", base: "10px" }}
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={requisition?.reason}
            size={{ lg: "16px", md: "14px", base: "12px" }}
            color="var(--light_gray)"
            weight="medium"
          />
        </Flex>
        <Flex w="100%" align="center" gap="1rem">
          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="HOP Decision"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={requisition?.hoc_decision}
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight="medium"
            />
          </Flex>

          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="HOP Comment"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={requisition?.hoc_comment}
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight="medium"
            />
          </Flex>
        </Flex>

        <Flex w="100%" align="center" gap="1rem">
          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="HOD Decision"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={requisition?.hod_decision}
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight="medium"
            />
          </Flex>

          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="HOD Comment"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={requisition?.hod_comment}
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight="medium"
            />
          </Flex>
        </Flex>

        <Flex w="100%" align="center" gap="1rem">
          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="Status"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={
                +requisition?.status === 0
                  ? "Pending"
                  : +requisition?.status === 1
                  ? "Approved by HOC"
                  : +requisition?.status === 2
                  ? "Approved by HOD"
                  : +requisition?.status === 3
                  ? "Approved"
                  : "Disbursed"
              }
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight="medium"
            />
          </Flex>
          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="Priority Level"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={requisition?.priority_level}
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight="medium"
            />
          </Flex>
        </Flex>

        <Flex w="100%" align="center" justify="space-between">
          {isAllowed && (
            <Button
              onClick={onOpen}
              bg="brand.900"
              color="white"
              variant="solid"
              size="sm"
            >
              Add Comment
            </Button>
          )}
          {user?.job_role?.toLowerCase() === "accountant" &&
            +requisition?.status === 3 && (
              <Button
                onClick={updateStatus}
                bg="brand.900"
                color="white"
                variant="solid"
                size="sm"
              >
                Disbursed
              </Button>
            )}
          <Button
            onClick={sendBuzzHandler}
            bg="yellow.300"
            color="brand.900"
            variant="solid"
            isLoading={loading}
            loadingText="Processing"
            leftIcon={<AiOutlineBell />}
            size="sm"
          >
            Send Reminder
          </Button>
        </Flex>
      </Flex>
      <RequisitionDetail
        isOpen={isOpen}
        onClose={onClose}
        requisition={requisition}
      />
    </Fragment>
  );
}
