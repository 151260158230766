import { Fragment, useContext, useEffect, useState } from "react";
import DashboardReusable from "../UI/DashboardReusable";
import BriefContainer from "./BriefContainer";

import { getBriefs, getClient } from "../../util/http";
import Loader from "../UI/Loader";
import { AuthContext } from "../../util/context";
import { getStoredItem } from "../../util/lib";

export default function Brief() {
  const [list, setList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(6);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const lastIndex = currentPage * perPage;
  const firstIndex = lastIndex - perPage;

  const { user } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        setLoading(true);
        let response;
        if (userType === "client") {
          response = await getClient(user?.client_id);
          console.log(response);
          setList(
            response?.client[0]?.briefs
              ?.filter((el) => el.status?.toLowerCase() !== "draft")
              .reverse()
          );
        } else {
          response = await getBriefs();

          const filtBrief = response?.briefs
            ?.filter((el, i) => {
              const lawyer = JSON.parse(el.lawyer_id);

              return lawyer?.includes(String(user?.id));
            })
            .reverse();

          if (user?.job_role?.toLowerCase() === "lawyer") {
            setList(filtBrief);
          } else if (
            user?.job_role?.toLowerCase().includes("hod") ||
            user?.job_role?.toLowerCase() === "hop"
          ) {
            setList(
              response?.briefs?.reverse().filter((el) => {
                return (
                  el.brief_type?.toLowerCase() ===
                  user?.department?.toLowerCase()
                );
              })
            );
          } else {
            setList(response?.briefs?.reverse());
          }
        }
        setLoading(false);
        setProgress(80);
        setProgress(100);
      } catch (error) {
        setLoading(false);
        setProgress(100);
        console.log(error);
      }
    }
    fetchData();
  }, [user, userType]);

  useEffect(() => {
    setTotal(list?.length);
  }, [list]);

  const briefList = list?.slice(firstIndex, lastIndex);

  return (
    <Fragment>
      <DashboardReusable>
        <Loader progress={progress} setProgress={setProgress} />
        <BriefContainer
          list={briefList}
          setList={setList}
          total={total}
          perPage={perPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          loading={loading}
          setPage={setCurrentPage}
        />
      </DashboardReusable>
    </Fragment>
  );
}
