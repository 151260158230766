import React, { useContext } from "react";
import Calender from "../../calender/Calender";
import { AuthContext } from "../../../util/context";
import Login from "../../auth/Login";
import Dashboard from "../../dashboard/Dashboard";

export default function CalenderPage() {
  let view;
  const { isAuthenticated, user, isUser } = useContext(AuthContext);

  const viewCalendar = true;
  // user?.job_role?.toLowerCase() === "lawyer" ||
  // user?.job_role?.toLowerCase().includes("hod") ||
  // user?.job_role?.toLowerCase() === "hop" ||
  // user?.job_role?.toLowerCase() === "hr" ||
  // user?.job_role?.toLowerCase() === "seed";

  if (viewCalendar) {
    view = <Calender />;
  } else {
    view = <Dashboard />;
  }
  return <>{isAuthenticated && isUser ? view : <Login />}</>;
}
