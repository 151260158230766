import {
  Avatar,
  Button,
  Flex,
  Heading,
  Input,
  FormLabel,
} from "@chakra-ui/react";
import { BsFillReplyFill } from "react-icons/bs";
import TextareaComponent from "../../../UI/Textarea";
import { GrAttachment } from "react-icons/gr";
import { updateTask, getTask } from "../../../../util/http";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import PreviewDocument from "../../../brief/create brief/PreviewDocument";
import { useParams } from "react-router-dom";

function ReusableComment({ el }) {
  return (
    <Flex py="1rem" w="100%" align="start" gap="1rem">
      <Avatar size="xs" name={el?.user} />
      <Flex align="start" direction="column" gap="8px">
        <Flex align="center" gap="1rem">
          <Heading
            textTransform="capiitalize"
            size="sm"
            color="#333"
            fontWeight="bold"
          >
            {el?.user}
          </Heading>
          <Heading size="xs" color="#333" fontWeight="normal">
            {new Date(el?.updated_at).toLocaleDateString()}{" "}
            {new Date(el?.updated_at).toLocaleTimeString()}
          </Heading>
        </Flex>
        <Heading size="xs" color="#333" fontWeight="normal">
          {el?.comment}
        </Heading>
        {false && (
          <Button variant="ghost" leftIcon={<BsFillReplyFill />}>
            Reply
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export default function NewTaskComments({ task, setTask }) {
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);
  const params = useParams();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  async function addComment() {
    if (!comment) {
      toast({
        title: `Add a comment`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await updateTask(task?.id, {
        comment,
        "attachments[]": files,
      });
      const response = await getTask(params.id);
      setTask(response.task[0]);
      toast({
        title: `Comment added`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setComment("");
      setFiles([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      bg="white"
      gap="1rem"
      boxShadow="lg"
      borderRadius="16px"
      p="1rem"
    >
      <Heading size="md" color="#333" fontWeight="bold">
        Comments
      </Heading>
      <Flex
        w="100%"
        maxHeight="400px"
        overflow="scroll"
        align="start"
        direction="column"
      >
        {task?.comments?.reverse()?.map((el, i) => (
          <ReusableComment el={el} ky={i} />
        ))}
      </Flex>
      <Flex w="100%" align="start" direction="column" gap="6px">
        <Heading size="sm" color="#333" fontWeight="medium">
          Comment
        </Heading>
        <TextareaComponent
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          required={false}
          placeholder="Type your comment here"
        />
        <PreviewDocument files={files} setFiles={setFiles} />
        <Flex w="100%" gap="1rem" align="end" justify="end">
          <FormLabel
            display="flex"
            align="center"
            justify="space-between"
            htmlFor="file"
            cursor="pointer"
          >
            <Input
              id="file"
              display="none"
              type="file"
              name="document_upload"
              onChange={(e) => setFiles((prev) => [e.target.files[0], ...prev])}
              multiple
              accept=""
            />

            <GrAttachment />
          </FormLabel>

          <Button
            isLoading={loading}
            loadingText=""
            onClick={addComment}
            variant="solid"
            bg="brand.900"
            color="#fff"
          >
            Post Comment
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
