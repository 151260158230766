import { Button, CloseButton, Flex, Heading, useToast } from "@chakra-ui/react";
import Modal from "../../overlay/Modal";
import InputComponent from "../../UI/Input";
import { useState } from "react";
import { uploadEmployee } from "../../../util/http";

export default function BulkEmployeeUpload({ show }) {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  function closeModal() {
    show(false);
  }

  async function bulkHandler() {
    try {
      setLoading(true);
      await uploadEmployee({ file });
      toast({
        title: `Employees imported successfully`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Modal hide={closeModal}>
      <Flex w="100%" align="start" direction="column" gap="1rem" py="1rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading size="md" color="#333" fontWeight="bold">
            Bulk Upload Employee
          </Heading>
          <CloseButton onClick={closeModal} />
        </Flex>
        <InputComponent
          onChange={(e) => setFile(e.target.files[0])}
          label="Upload File"
          type="file"
        />
        <Button
          isLoading={loading}
          loadingText=""
          onClick={bulkHandler}
          variant="solid"
          bg="brand.900"
          color="white"
        >
          Upload
        </Button>
      </Flex>
    </Modal>
  );
}
