import { Avatar, Divider, Flex, Heading } from "@chakra-ui/react";
import { useState } from "react";

function ReusableDetail({ title1, title2, text1, text2 }) {
  return (
    <Flex w="100%" align="center" justify="space-between">
      <Flex w="50%" align="start" direction="column" gap="1rem">
        <Heading
          textTransform="capitalize"
          size="sm"
          color="var(--light_gray)"
          fontWeight="bold"
        >
          {title1}
        </Heading>
        <Heading
          textTransform="capitalize"
          size="xs"
          color="var(--light_gray)"
          fontWeight="normal"
        >
          {text1}
        </Heading>
      </Flex>

      <Flex w="50%" align="start" direction="column" gap="1rem">
        <Heading
          textTransform="capitalize"
          size="sm"
          color="var(--light_gray)"
          fontWeight="bold"
        >
          {title2}
        </Heading>
        <Heading
          textTransform="capitalize"
          size="xs"
          color="var(--light_gray)"
          fontWeight="normal"
        >
          {text2}
        </Heading>
      </Flex>
    </Flex>
  );
}

export default function Detail({ employee }) {
  const secDepts =
    employee?.secondary_departments &&
    JSON.parse(employee?.secondary_departments);

  const newArr = employee?.image_path?.split("/");
  newArr?.splice(0, 3);
  const newPath = newArr?.join("/");

  return (
    <Flex p="1rem" w="100%" align="start" direction="column" gap="1rem">
      <Avatar
        size="2xl"
        src={`https://${newPath}`}
        name={`${employee?.first_name} ${employee?.last_name}`}
      />

      <Flex w="100%" align="start" direction="column" gap="1rem">
        <ReusableDetail
          title1="First Name"
          text1={employee?.first_name}
          title2="Last Name"
          text2={employee?.last_name}
        />
        <Divider />
        <ReusableDetail
          title1="Personal Email"
          text1={employee?.personal_email}
          title2="Official Email"
          text2={employee?.email}
        />
        <Divider />
        <ReusableDetail
          title1="Phone Number"
          text1={employee?.phone_number}
          title2="Employee Id"
          text2={employee?.employee_id}
        />

        <Divider />
        <ReusableDetail
          title1="Role"
          text1={employee?.job_role}
          title2="Designation"
          text2={employee?.designation}
        />

        <Divider />
        <ReusableDetail
          title1="Primary Department"
          text1={employee?.department}
          title2="Leave Count"
          text2={employee?.leave_count}
        />
        <Divider />
        <Flex w="100%" align="start" direction="column" gap="1rem">
          <Heading size="sm" color="#333" fontWeight="bold">
            Secondary Departments
          </Heading>
          <Flex w="100%" align="center" gap="4px" wrap="wrap">
            {secDepts?.map((el) => (
              <Heading size="sm" color="#333" fontWeight="normal">
                {el}
              </Heading>
            ))}
          </Flex>
        </Flex>

        <Divider />
        <ReusableDetail
          title1="Address"
          text1={employee?.address}
          title2="Country"
          text2={employee?.country}
        />

        <Divider />
        <ReusableDetail
          title1="State"
          text1={employee?.state}
          title2="City"
          text2={employee?.city}
        />

        <Divider />
        <ReusableDetail
          title1="Postal Code"
          text1={employee?.postal_code}
          title2=""
          text2=""
        />

        <Divider />
        <ReusableDetail
          title1="Contact First Name"
          text1={employee?.contact_first_name}
          title2="Contact Last Name"
          text2={employee?.contact_last_name}
        />

        <Divider />
        <ReusableDetail
          title1="Contact Email"
          text1={employee?.contact_email}
          title2="Contact Phone Number"
          text2={employee?.contact_phone}
        />

        <Divider />
        <ReusableDetail
          title1="Contact Address"
          text1={employee?.contact_address}
          title2="Contact Country"
          text2={employee?.contact_country}
        />

        <Divider />
        <ReusableDetail
          title1="Contact State"
          text1={employee?.contact_state}
          title2="Contact City"
          text2={employee?.contact_city}
        />

        <Divider />
        <ReusableDetail
          title1="Contact Postal Code"
          text1={employee?.contact_postal_code}
          title2=""
          text2=""
        />
      </Flex>
    </Flex>
  );
}
