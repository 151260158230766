import {
  Avatar,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import InputComponent from "../../UI/Input";
import SelectComponent from "../../UI/Select";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";
import ReusableAmountInput from "../../UI/ReusableAmountInput";
import { useEffect } from "react";
import { listDepartments, listDesignations } from "../../../util/http";

export default function Details({
  show = true,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setEmployeeId,
  setRole,
  setImage,
  setLeave,
  setSalary,
  setDepartment,
  setStorage,
  role,
  setDesignation,
  setPrimaryRole,
  setSecondaryRoles,
  setPersonalEmail,
}) {
  const [url, setUrl] = useState("");
  const [dept, setDept] = useState([]);
  const [desig, setDesig] = useState([]);

  const { user } = useContext(AuthContext);

  const adminRoles = [
    "seed",
    "hr",
    "accountant",
    "hod",
    "hop",
    "lawyer",
    "user",
  ].map((a) => a.charAt(0).toUpperCase() + a.slice(1));
  const hrRoles = ["hod", "hop", "lawyer", "user"].map(
    (a) => a.charAt(0).toUpperCase() + a.slice(1)
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listDepartments();
        setDept(
          response?.departments?.map(
            (el) =>
              el?.department?.charAt(0).toUpperCase() +
              el?.department?.toLowerCase().slice(1)
          )
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listDesignations();
        setDesig(
          response?.designations?.map(
            (el) =>
              el?.designation?.charAt(0).toUpperCase() +
              el?.designation?.toLowerCase().slice(1)
          )
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Flex
      w="100%"
      h={{ lg: "900px", md: "100%", base: "100%" }}
      direction="column"
      align="start"
      gap="2rem"
    >
      <input
        onChange={(e) => {
          setImage(e.target.files[0]);
          setUrl(URL.createObjectURL(e.target.files[0]));
        }}
        id="image"
        style={{ display: "none" }}
        type="file"
      />
      <label htmlFor="image">
        <Avatar
          size={{ lg: "2xl", md: "xl", base: "lg" }}
          name="loya"
          src={url}
        />
      </label>
      <Flex align="center" direction="column" gap="2rem" w="100%">
        <Flex
          align="center"
          w="100%"
          justify="center"
          gap="1rem"
          direction={{ lg: "row", md: "column", base: "column" }}
        >
          <InputComponent
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Enter first name"
            label="First Name"
            type="text"
          />
          <InputComponent
            label="Last Name"
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Enter last name"
            type="text"
          />
        </Flex>

        <Flex align="center" w="100%" justify="center" gap="1rem">
          <InputComponent
            placeholder="Enter email address"
            label="Official Email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputComponent
            placeholder="Enter email address"
            label="Personal Email"
            type="email"
            onChange={(e) => setPersonalEmail(e.target.value)}
          />
        </Flex>
        <SelectComponent
          label="Select Secondary Departments"
          onChange={(e) => {
            const roles = e?.map((el) => el.value);
            setSecondaryRoles(roles);
          }}
          multiple={true}
          options={dept || []}
        />

        {show && (
          <Flex align="center" w="100%" justify="center" gap="1rem">
            <InputComponent
              placeholder="LOAXXX"
              label="Employee ID"
              type="text"
              onChange={(e) => setEmployeeId(e.target.value)}
            />
            <SelectComponent
              onChange={(e) => setRole(e.value)}
              options={
                user.job_role?.toLowerCase() === "hr" ? hrRoles : adminRoles
              }
              label="Permission Level"
            />
          </Flex>
        )}
        <Flex w="100%" align="start" justify="start" gap="1rem">
          <SelectComponent
            label="Select Primary Department"
            onChange={(e) => setPrimaryRole(e.value)}
            options={dept || []}
          />

          <FormControl>
            <FormLabel>Phone Number</FormLabel>
            <Flex border="1px solid #ccc" borderRadius="0.5rem" align="center">
              <Select border="none" w="30%">
                <option value="234">+234</option>
              </Select>
              <Input
                type="number"
                border="none"
                onChange={(e) => setPhone(e.target.value)}
                placeholder="XXX XXX XXXX"
                focusBorderColor="transparent"
              />
            </Flex>
          </FormControl>
        </Flex>
        <Flex w="100%" align="start" justify="start" gap="1rem">
          <SelectComponent
            label="Storage Capacity"
            onChange={(e) => setStorage(e.value)}
            options={[
              "Select Storage Capacity",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
            ]}
          />

          <SelectComponent
            label="Select Designation"
            onChange={(e) => setDesignation(e.value)}
            options={desig || []}
          />
        </Flex>
        <Flex align="center" w="100%" justify="center" gap="1rem">
          {false && (
            <InputComponent
              placeholder="30 days"
              label="Leave Days"
              type="text"
              onChange={(e) => setLeave(e.target.value)}
            />
          )}
          <ReusableAmountInput
            placeholder="₦ 100,000"
            label="Salary"
            type="text"
            onChange={(e) => setSalary(e.target.rawValue)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
